<template>
  <v-container>
    <!-- toolbar -->
    <v-row>
      <v-col cols="12">
        <v-toolbar dense color="primary" dark flat rounded>
          <v-toolbar-title>Submit an Event Report</v-toolbar-title>
          <v-spacer />
          <v-btn to="/" text :loading="loading" :disabled="loading">Home</v-btn>
          <!-- dark mode button -->
          <v-btn
            icon
            @click="$vuetify.theme.dark = !$vuetify.theme.dark"
            :loading="loading"
            :disabled="loading"
          >
            <v-icon>{{
              $vuetify.theme.dark ? "mdi-lightbulb-outline" : "mdi-lightbulb"
            }}</v-icon>
          </v-btn>
          <!-- dark mode button -->
        </v-toolbar>
      </v-col>
    </v-row>
    <!-- toolbar -->
    <!-- important event report information -->
    <v-row>
      <v-col cols="12" md="8"
        ><v-card outlined :loading="loading">
          <v-card-title class="headline font-weight-medium">
            Important Event Report Information
          </v-card-title>
          <v-card-text class="body-1 text--primary mt-4 mb-0 pb-2">
            <p>
              Please complete the required information below immediately after
              your event
              <strong
                >(required information is marked with
                <span class="red--text">*</span>).</strong
              >
            </p>
            <p>
              After completing and submitting the form, you'll receive an
              invoice via email for you order.
            </p>
            <p>
              Immediately after payment is received, we will email the
              individual congratulations emails to you at the email address you
              provide below in the field titled
              <strong>"Event Contact Person Email".</strong>
              These emails contain all the information necessary for your
              winning bidders to register their package.
            </p>
            <p>
              Upon receiving this information, you will forward the information
              to the individual winners and retain a copy for future reference.
            </p>
            <p>
              <strong
                >Please have the following information ready before proceeding
                with the form below:</strong
              >
            </p>
            <p>
              <span class="d-block my-1">
                <v-icon left small color="green">mdi-check</v-icon>
                Event Name, Event Date
              </span>
              <span class="d-block my-1">
                <v-icon left small color="green">mdi-check</v-icon>
                Organization Contact Person Name and Email Address
              </span>
              <span class="d-block my-1">
                <v-icon left small color="green">mdi-check</v-icon>
                Organization Legal Name, Organization Billing Address
              </span>
              <span class="d-block my-1">
                <v-icon left small color="green">mdi-check</v-icon>
                All Winner Package Codes
              </span>
              <span class="d-block my-1">
                <v-icon left small color="green">mdi-check</v-icon>
                All Winner Last Names
              </span>
            </p>
            <p class="pt-2 red--text text--darken-1">
              <strong>
                For your privacy &amp; safety, incomplete information is not
                saved and/or submitted.</strong
              >
            </p>
            <p class="pt-2">Thank you!</p>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- add winner gif -->
      <v-col cols="12" md="4"
        ><v-card outlined :loading="loading">
          <v-card-text class="text--primary">
            <p class="title font-weight-medium">
              How to Add Your Winning Bidders
            </p>
            <p class="body-1">
              <strong
                >You do not need to submit multiple invoices if you have more
                than 1 winning bidder.</strong
              >
            </p>
            <p class="body-1">
              You may add as many winning bidders as needed using the
              <strong>Add Another Winner button.</strong>
            </p>
            <p
              @click="howToAddWinnerDialog = true"
              style="cursor: pointer"
              class="elevation-1"
            >
              <v-img src="@/assets/addWinnerInvoiceGif.gif" cover />
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- important event report information -->

    <!-- event report form -->
    <v-row>
      <v-col cols="12">
        <v-card outlined class="d-md-flex flex-md-column" :loading="loading">
          <v-card-title class="display-1">Submit an Event Report</v-card-title>
          <v-card-subtitle class="pt-2 text--primary">
            Please complete the form below to submit an event report for your
            event
          </v-card-subtitle>
          <v-card-text class="text--primary">
            <!-- form -->
            <v-form ref="form" @submit.prevent="validateForm">
              <!-- event info -->
              <!-- section header -->
              <v-row>
                <v-col cols="12"
                  ><v-subheader class="body-1 font-weight-bold text--primary"
                    >Event Information</v-subheader
                  ></v-col
                >
              </v-row>
              <!-- section header -->
              <v-row class="mt-n5">
                <!-- report completed by -->
                <v-col cols="12" md="4"
                  ><v-subheader class="mt-n1 caption"
                    >Report Completed By *</v-subheader
                  >

                  <v-radio-group
                    v-model.trim="reportCompletedBy"
                    dense
                    class="py-0 my-0"
                    :rules="[textRules.required]"
                    :loading="loading"
                    :disabled="loading"
                  >
                    <v-radio
                      v-for="(item, index) in [
                        'Organization',
                        'Auctioneer',
                        'Other',
                      ]"
                      :key="index"
                      :label="item"
                      :value="item.toLowerCase()"
                      required
                    ></v-radio>
                  </v-radio-group>
                  <v-expand-transition>
                    <v-text-field
                      v-if="reportCompletedBy == 'other'"
                      label="Report Completed By Name *"
                      v-model.trim="reportCompletedByOtherName"
                      required
                      :rules="[textRules.required]"
                    ></v-text-field> </v-expand-transition
                ></v-col>
                <v-col cols="12" md="8">
                  <v-row class="mt-n6">
                    <!-- organization name -->
                    <v-col cols="12" md="6">
                      <v-text-field
                        :loading="loading"
                        :disabled="loading"
                        label="Organization Legal Name *"
                        v-model.trim="organizationName"
                        required
                        :rules="[textRules.required]"
                      ></v-text-field>
                    </v-col>
                    <!-- org billing address -->
                    <v-col cols="12" md="6">
                      <v-text-field
                        :loading="loading"
                        :disabled="loading"
                        label="Organization Billing Address"
                        v-model.trim="organizationAddress"
                      ></v-text-field>
                    </v-col>
                    <!-- event name -->
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :loading="loading"
                        :disabled="loading"
                        label="Event Name *"
                        v-model.trim="eventName"
                        required
                        :rules="[textRules.required]"
                      ></v-text-field>
                    </v-col>

                    <!-- event date -->
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :loading="loading"
                        :disabled="loading"
                        :value="formatDate(eventDate)"
                        label="Event Date *"
                        readonly
                        required
                        :rules="[textRules.required]"
                        @click="eventDateDialog = true"
                      ></v-text-field>
                      <!-- event date dialog -->
                      <v-dialog v-model="eventDateDialog" width="290px">
                        <v-card :loading="loading" :disabled="loading">
                          <v-date-picker
                            @change="eventDateDialog = false"
                            v-model.trim="eventDate"
                            scrollable
                          >
                          </v-date-picker>
                        </v-card>
                      </v-dialog>
                      <!-- event date dialog -->
                    </v-col>
                    <!-- event date -->
                  </v-row>
                  <v-row>
                    <!-- event contact person last name -->
                    <v-col cols="12" sm="6">
                      <v-text-field
                        label="Event Contact Person Last Name *"
                        :loading="loading"
                        :disabled="loading"
                        v-model.trim="eventContactLastName"
                        required
                        :rules="[textRules.required]"
                      ></v-text-field>
                    </v-col>
                    <!-- event contact person last name -->

                    <!-- event contact person first name -->
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :loading="loading"
                        :disabled="loading"
                        label="Event Contact Person First Name *"
                        v-model.trim="eventContactFirstName"
                        required
                        :rules="[textRules.required]"
                      ></v-text-field>
                    </v-col>
                    <!-- event contact person first name -->

                    <!-- event contact person phone   -->
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :loading="loading"
                        :disabled="loading"
                        @keypress="limitPhoneValues($event)"
                        label="Event Contact Person Phone *"
                        v-model.trim="eventContactPhoneNumber"
                        required
                        :rules="[textRules.required, phoneRules.tenNumbers]"
                        persistent-hint
                        type="number"
                        hint="Please enter numbers only. +1 is not necessary."
                        counter
                      ></v-text-field>
                    </v-col>
                    <!-- event contact person phone   -->

                    <!-- event contact person email -->
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :loading="loading"
                        :disabled="loading"
                        v-model.trim="eventContactEmail"
                        @input="
                          eventContactEmail = eventContactEmail.toLowerCase()
                        "
                        label="Event Contact Person Email *"
                        type="email"
                        persistent-hint
                        hint="Important: Winner redemption emails will automatically be sent to this email address once your invoice has been paid. Please ensure you enter a working email that you have access receive communications to."
                        required
                        :rules="[textRules.required, emailRules.valid]"
                      ></v-text-field>
                    </v-col>
                    <!-- event contact person email -->
                  </v-row>
                </v-col>
              </v-row>
              <!-- divider -->
              <v-row>
                <v-col cols="12">
                  <v-divider class="mt-5" />
                </v-col>
              </v-row>
              <!-- divider -->
              <!-- event info -->
              <!-- team member info -->
              <v-row>
                <!-- section header -->
                <v-col cols="12"
                  ><v-subheader
                    class="body-1 text--primary font-weight-bold mt-2"
                    >Team Member Information</v-subheader
                  ></v-col
                >
              </v-row>
              <!-- section header -->
              <v-row class="mt-3">
                <!-- useAuctioneer -->
                <v-col cols="12" md="4">
                  <v-subheader class="caption px-0 pb-5"
                    >Did you use an auctioneer or other event professional?</v-subheader
                  >
                  <v-radio-group
                    v-model.trim="auctioneerUsed"
                    :loading="loading"
                    :disabled="loading"
                    dense
                    class="py-0 my-0"
                    :rules="[radioRules.requiredBoolean]"
                  >
                    <v-radio
                      v-for="(item, index) in [
                        { text: 'Yes', value: true },
                        { text: 'No', value: false },
                      ]"
                      :key="index"
                      :label="item.text"
                      :value="item.value"
                      required
                    ></v-radio>
                  </v-radio-group>
                  <v-expand-transition>
                    <v-text-field
                      :loading="loading"
                      :disabled="loading"
                      v-if="auctioneerUsed"
                      label="Auctioneer/Event Professional Name *"
                      v-model.trim="auctioneerName"
                      required
                      :rules="[textRules.required]"
                    ></v-text-field>
                  </v-expand-transition>
                  <v-expand-transition>
                    <v-text-field
                      :loading="loading"
                      :disabled="loading"
                      v-if="auctioneerUsed"
                      label="Auctioneer/Event Professional Company"
                      v-model.trim="auctioneerCompany"
                    ></v-text-field>
                  </v-expand-transition>
                  <v-expand-transition>
                    <v-text-field
                      :loading="loading"
                      :disabled="loading"
                      v-if="auctioneerUsed"
                      @input="auctioneerEmail = auctioneerEmail.toLowerCase()"
                      v-model.trim="auctioneerEmail"
                      label="Auctioneer/Event Professional Email *"
                      type="email"
                      required
                      :rules="[textRules.required, emailRules.valid]"
                    ></v-text-field> </v-expand-transition
                ></v-col>
                <!-- useAuctioneer -->

                <!-- useSavTeamMember -->
                <v-col cols="12" md="4">
                  <v-subheader class="caption px-0 pb-5"
                    >Did a member of the Charity Getaways sales team assist you with this event? *</v-subheader
                  >
                  <v-radio-group
                    :loading="loading"
                    :disabled="loading"
                    v-model.trim="savSalesUsed"
                    dense
                    class="py-0 my-0"
                    :rules="[radioRules.requiredBoolean]"
                  >
                    <v-radio
                      v-for="(item, index) in [
                        { text: 'Yes', value: true },
                        { text: 'No', value: false },
                      ]"
                      :key="index"
                      :label="item.text"
                      :value="item.value"
                      required
                    ></v-radio>
                  </v-radio-group>
                  <v-expand-transition>
                    <v-select
                      :loading="loading"
                      :disabled="loading"
                      dense
                      v-if="savSalesUsed"
                      :items="[
                        'April McGill',
                        'Jeannie Retherford',
                        'Other',
                      ]"
                      v-model="savSalesName"
                      required
                      label="Charity Getaways Team Member *"
                      :rules="[textRules.required]"
                    ></v-select>
                  </v-expand-transition>
                  <v-expand-transition>
                    <v-text-field
                      :loading="loading"
                      :disabled="loading"
                      v-if="savSalesUsed && savSalesName == 'Other'"
                      label="Charity Getaways Team Member *"
                      v-model.trim="savSalesOtherName"
                      :rules="[(v) => !!v || 'Field Required']"
                    ></v-text-field> </v-expand-transition
                ></v-col>

                <!-- useSavTeamMember -->
              </v-row>
              <!-- divider -->
              <v-row>
                <v-col cols="12">
                  <v-divider class="mt-5" />
                </v-col>
              </v-row>
              <!-- divider -->
              <!-- team member info -->
              <!-- winner info -->
              <!-- section header -->
              <v-row>
                <v-col cols="12"
                  ><v-subheader
                    class="body-1 font-weight-bold mt-2 text--primary"
                    >Winner Information</v-subheader
                  ></v-col
                >
              </v-row>
              <!-- section header -->
              <v-row
                v-for="(item, index) in winners"
                :key="index"
                class="rounded mb-2 mx-0 mt-3"
                :class="[
                  $vuetify.theme.dark ? '' : 'grey lighten-3',
                  index > 0 ? 'mt-4' : '',
                ]"
                :style="$vuetify.theme.dark ? 'border:1px solid #f1f1f1' : ''"
              >
                <!-- subheader -->
                <v-col cols="12">
                  <v-subheader
                    class="title text--primary px-0 font-weight-regular"
                    ><span class="d-block"
                      >Winner {{ index + 1 }} Information</span
                    >
                    <div class="ml-auto">
                      <v-btn
                        :loading="loading"
                        :disabled="loading"
                        depressed
                        color="error"
                        v-if="index > 0"
                        @click="winners.splice(index, 1)"
                        class="mr-2"
                        >Remove This Winner
                        <v-icon right>mdi-minus-circle-outline</v-icon>
                      </v-btn>
                      <v-btn
                        :loading="loading"
                        :disabled="loading"
                        color="primary"
                        depressed
                        @click="
                          winners.push({
                            costToOrg: '',
                            destination: '',
                            isRegistered: false,
                            lastName: '',
                            numberNights: '',
                            packageCode: '',
                            packageID: '',
                            unitType: '',
                          })
                        "
                        >Add Another Winner
                        <v-icon right>mdi-plus-circle-outline</v-icon>
                      </v-btn>
                    </div>
                  </v-subheader>
                </v-col>
                <!-- winners -->
                <v-col cols="12" md="4">
                  <v-row>
                    <!-- winner last name -->
                    <v-col cols="12">
                      <v-text-field
                        :loading="loading"
                        :disabled="loading"
                        label="Last Name *"
                        v-model.trim="item.lastName"
                        required
                        :rules="[textRules.required]"
                      ></v-text-field>
                    </v-col>
                    <!-- winner last name -->
                    <!-- winner package code -->
                    <v-col cols="12">
                      <v-autocomplete
                        :loading="loading"
                        :disabled="loading"
                        dense
                        @change="onSetWinnerInfo(index, $event)"
                        :items="packages"
                        item-text="packageCode"
                        return-object
                        v-model.trim="item.packageCode"
                        label="Package Code *"
                        :rules="[textRules.required]"
                        hint="Select or type in package code"
                        persistent-hint
                      ></v-autocomplete>
                      <!-- <v-select
                        :loading="loading"
                        :disabled="loading"
                        dense
                        @change="onSetWinnerInfo(index, $event)"
                        :items="packages"
                        item-text="packageCode"
                        return-object
                        v-model.trim="item.packageCode"
                        label="Package Code *"
                        :rules="[textRules.required]"
                      ></v-select> -->
                    </v-col>
                    <!-- winner package code -->
                  </v-row>
                </v-col>

                <!-- winner package information -->
                <v-col cols="12" md="8" class="mt-2">
                  <v-row>
                    <v-col cols="12" md="4">
                      <!-- Destination -->
                      <p v-if="item.packageCode">
                        <span class="body-2 d-block">Destination</span>
                        <span class="caption d-block text--secondary">{{
                          item.destination
                        }}</span>
                      </p>
                      <!-- Destination -->
                      <!-- unit type -->
                      <p v-if="item.packageCode">
                        <span class="body-2 d-block">Unit Type</span>
                        <span class="caption d-block text--secondary">{{
                          item.unitType
                        }}</span>
                      </p>
                      <!-- unit type -->
                    </v-col>
                    <v-col cols="12" md="4">
                      <!-- cost to org -->
                      <p v-if="item.packageCode">
                        <span class="body-2 d-block">Cost to Organization</span>
                        <span class="caption d-block text--secondary">{{
                          item.costToOrg ? "$" + item.costToOrg + ".00" : ""
                        }}</span>
                      </p>
                      <!-- cost to org -->
                      <!-- number nights -->
                      <p v-if="item.packageCode">
                        <span class="body-2 d-block">Number of Nights</span>
                        <span class="caption d-block text--secondary">{{
                          item.numberNights
                        }}</span>
                      </p>
                      <!-- number nights -->
                    </v-col>
                    <!-- packageID -->
                    <!-- <v-col cols="4">
                      <strong>Package ID: {{ item.packageID }}</strong>
                    </v-col> -->
                    <!-- packageID -->
                  </v-row>
                </v-col>
                <!-- winner package information -->
              </v-row>
              <!-- divider -->
              <v-row>
                <v-col cols="12">
                  <v-divider class="mt-5" />
                </v-col>
              </v-row>
              <!-- divider -->
              <!-- winner info -->
              <!-- invoice info -->
              <!-- section header -->
              <v-row>
                <v-col cols="12"
                  ><v-subheader
                    class="body-1 text--primary font-weight-bold mt-2"
                    >Invoice Information</v-subheader
                  ></v-col
                >
              </v-row>
              <!-- section header -->
              <v-row class="mt-n1">
                <v-col cols="12" md="4">
                  <!-- number of winners -->
                  <p>
                    <span class="body-2 d-block">Number of Winners</span>
                    <span class="caption d-block text--secondary">
                      {{ winners[0].lastName != "" ? winners.length : 0 }}
                    </span>
                  </p>
                  <!-- invoice total -->
                  <p>
                    <span class="body-2 d-block">Invoice Total</span>
                    <span class="mt-1 d-block">${{ total }}.00</span>
                  </p>
                </v-col>
                <!-- additional email -->
                <v-col cols="12" md="4">
                  <v-subheader class="body-2 px-0 pb-2 mt-0"
                    >Email a copy of this invoice to an additional email
                    address?*</v-subheader
                  >
                  <v-radio-group
                    :loading="loading"
                    :disabled="loading"
                    v-model.trim="sendToAdditionalEmail"
                    dense
                    class="py-0 my-0"
                    :rules="[radioRules.requiredBoolean]"
                  >
                    <v-radio
                      v-for="(item, index) in [
                        { text: 'Yes', value: true },
                        { text: 'No', value: false },
                      ]"
                      :key="index"
                      :label="item.text"
                      :value="item.value"
                      required
                    ></v-radio>
                  </v-radio-group>
                  <v-expand-transition>
                    <v-text-field
                      :loading="loading"
                      :disabled="loading"
                      v-if="sendToAdditionalEmail"
                      @input="additionalEmail = additionalEmail.toLowerCase()"
                      v-model.trim="additionalEmail"
                      type="email"
                      persistent-hint
                      hint="This email will receive a copy of this invoice"
                      label="Additional Email *"
                      required
                      :rules="[textRules.required, emailRules.valid]"
                    ></v-text-field>
                  </v-expand-transition>
                </v-col>
                <!-- additional email -->

                <!-- email to auctioneer -->
                <v-col cols="12" md="4" v-if="auctioneerUsed">
                  <v-subheader class="body-2 px-0 pb-2 mt-n2"
                    >Email a copy of this invoice to your auctioneer?*
                  </v-subheader>
                  <v-radio-group
                    :loading="loading"
                    :disabled="loading"
                    v-if="auctioneerUsed"
                    v-model.trim="sendToAuctioneerEmail"
                    dense
                    class="py-0 my-0"
                    :rules="[radioRules.requiredBoolean]"
                  >
                    <v-radio
                      v-for="(item, index) in [
                        { text: 'Yes', value: true },
                        { text: 'No', value: false },
                      ]"
                      :key="index"
                      :label="item.text"
                      :value="item.value"
                      required
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <!-- email to auctioneer -->
              </v-row>
              <!-- invoice info -->
              <!-- divider -->
              <v-row>
                <v-col cols="12">
                  <v-divider class="mt-5" />
                </v-col>
              </v-row>
              <!-- divider -->
            </v-form>
            <!-- form -->
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-spacer />
            <!-- submit button -->
            <v-btn
              color="primary"
              depressed
              @click="validateForm"
              :loading="loading"
              :disabled="loading"
            >
              Submit Your Event Report</v-btn
            >
            <!-- submit button -->
          </v-card-actions>
        </v-card>
      </v-col>
      <!-- form -->
    </v-row>
    <!-- event report form -->

    <!-- error dialog -->
    <v-dialog v-model="errorDialog" width="500" :overlay-opacity="1" persistent>
      <v-card outlined :loading="loading">
        <v-card-text
          class="
            headline
            font-weight-medium
            mt-6
            red--text
            text--darken-2
            d-flex
          "
        >
          <v-icon left color="error">mdi-alert-outline</v-icon> Form Submission
          Error</v-card-text
        >
        <v-card-text class="text--primary body-1">
          <v-alert type="error" text outlined>
            We've encountered an error. Your invoice has
            <strong>NOT</strong> been submitted successfully.</v-alert
          >
        </v-card-text>
        <v-card-text class="text--primary body-1"
          >Please contact us via email for further assistance at
          <strong
            ><a
              href="mailto:accounting@silentauctionvacations.com?subject=Event%20Report%20Submission%20Error"
              target="_blank"
              >Accounting@SilentAuctionVacations.com</a
            ></strong
          ></v-card-text
        >
        <v-card-text class="text--primary body-1"
          >Our apologies for any inconvenience.</v-card-text
        >
        <v-card-text class="text--primary body-1 mb-4">Thank You!</v-card-text>
      </v-card>
    </v-dialog>
    <!-- error dialog -->

    <!-- success dialog -->
    <v-dialog
      v-model="successDialog"
      width="500"
      :overlay-opacity="1"
      persistent
    >
      <v-card outlined :loading="loading">
        <v-card-text class="text--primary headline font-weight-medium mt-6">
          You've Succesfully Submitted Your Event Report!</v-card-text
        >

        <v-card-text class="mt-6 mb-2">
          <v-alert type="info" :icon="false" text>
            <p>
              <strong>Please note that our mailing address has changed.</strong>
            </p>
            <p>Please mail all checks to the address below:</p>
            <p class="font-weight-bold mb-0">
              <span>Charity Getaways</span><br />
              <span>P.O. Box 160535</span><br />
              <span>Altamonte Springs, FL 32716</span>
            </p>
          </v-alert>
        </v-card-text>

        <v-card-text class="text--primary body-1"
          >A copy of your invoice has been emailed to you. Please check your
          spam folder if it doesn't arrive within 15 minutes.</v-card-text
        >

        <v-card-text class="text--primary body-1 mb-2">Thank You!</v-card-text>

        <v-card-actions>
          <v-btn block color="primary" to="/" depressed class="mx-0"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- success dialog -->

    <!-- help dialog -->
    <v-dialog v-model="confirmSingleWinnerDialog" width="500">
      <v-card outlined :loading="loading">
        <v-card-text class="text--primary headline font-weight-medium mt-6">
          Please note that you can add more than 1 winner to this
          invoice.</v-card-text
        >
        <v-card-text class="body-1 text--primary mt-3 mb-0 pb-2">
          <p class="pt-2">
            We noticed that you've listed 1 winner in your invoice. Please note
            that
            <strong>you can add multiple winners per invoice </strong>and do not
            need to create a seperate invoice for each winner.
          </p>
        </v-card-text>
        <v-card-text class="body-1 text--primary pb-2 mb-8">
          <h4 class="title">
            Do you need to add additional winners to this invoice?
          </h4>
        </v-card-text>
        <hr />
        <v-card-actions class="flex-column flex-sm-row">
          <v-btn
            color="primary"
            outlined
            class="my-2 my-sm-0"
            @click="confirmSingleWinnerDialog = false"
            >Yes, add more winners</v-btn
          >
          <v-spacer />
          <v-btn
            color="success"
            depressed
            class="my-2 my-sm-0"
            @click="onSubmit"
            >No, submit my invoice</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- help dialog -->

    <!-- how to add winner dialog -->
    <v-dialog v-model="howToAddWinnerDialog" width="700">
      <v-card outlined :loading="loading">
        <v-card-text class="text--primary headline font-weight-medium mt-6">
          How to Add Your Winning Bidders</v-card-text
        >
        <v-card-text class="body-1 text--primary mt-3 mb-0 pb-2">
          <p>
            <strong
              >You do not need to submit multiple invoices if you have more than
              1 winning bidder.</strong
            >
            You may add as many winning bidders as needed using the
            <strong>Add Another Winner button.</strong>
          </p>

          <p>
            <v-img
              src="@/assets/addWinnerInvoiceGif.gif"
              cover
              class="elevation-1"
            />
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            depressed
            class="mx-0"
            @click="checkAchFeedbackStatus"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- how to add winner dialog -->

    <!-- interested in ACH payments dialog -->
    <v-dialog v-model="achPaymentsDialog" width="500" persistent>
      <v-card outlined :loading="loading">
        <v-card-text class="text--primary headline font-weight-medium mt-6">
          Would you prefer an option to submit your payment via ACH
          transfer?</v-card-text
        >
        <v-card-text class="body-1 text--primary mt-3 mb-0 pb-2">
          <p class="pt-2">
            We're always looking for ways to better serve you. We really
            appreciate your feedback.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" depressed @click="onAchFeedback(true)"
            >Yes
          </v-btn>
          <v-btn color="primary" text @click="onAchFeedback(false)">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- interested in ACH payments dialog -->
  </v-container>
</template>

<script>
import firebase from "@/plugins/firebase";
import moment from "moment";
import axios from "axios";
import formatDate from "@/mixins/formatDate";
import formRules from "@/mixins/formRules";
import limitPhoneValues from "@/mixins/limitPhoneValues";
export default {
  mixins: [formatDate, formRules, limitPhoneValues],
  firestore: {
    packages: firebase
      .firestore()
      .collection("retailPackages")
      .orderBy("packageCode"),
    achFeedback: firebase
      .firestore()
      .collection("userFeedback")
      .doc("travelerPortal")
      .collection("achPayments"),
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  data: () => ({
    ipAddress: "",
    achFeedback: [],
    achPaymentsDialog: false,
    packages: [],
    eventDateDialog: false,
    confirmSingleWinnerDialog: false,
    successDialog: false,
    errorDialog: false,
    howToAddWinnerDialog: false,
    winnerDialogAutoShown: false,
    additionalEmail: "",
    archived: false,
    auctioneerCompany: "",
    auctioneerEmail: "",
    auctioneerName: "",
    auctioneerUsed: "",
    dateCreated: new Date().toISOString().substring(0, 10),
    datePaid: "",
    dateUpdated: "",
    eventContactEmail: "",
    eventContactFirstName: "",
    eventContactLastName: "",
    eventContactPhoneNumber: "",
    eventDate: "",
    eventName: "",
    eventReportNumber: "",
    eventReportTotal: "",
    id: "",
    isPaid: false,
    numberOfWinners: "",
    organizationAddress: "",
    organizationName: "",
    reportCompletedBy: "",
    reportCompletedByOtherName: "",
    savSalesName: "",
    savSalesOtherName: "",
    savSalesUsed: "",
    sendToAdditionalEmail: "",
    sendToAuctioneerEmail: "",
    source: "sav traveler portal",
    travelByDate: "",
    winnerPackageIDs: [],
    winners: [
      {
        costToOrg: "",
        destination: "",
        isRegistered: false,
        lastName: "",
        numberNights: "",
        packageCode: "",
        packageID: "",
        unitType: "",
        travelByDate: "",
      },
    ],
  }),
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    total() {
      return this.winners
        .map((i) => {
          if (i.costToOrg == "" || isNaN(i.costToOrg)) {
            return 0;
          }
          return parseInt(i.costToOrg);
        })
        .reduce((a, b) => a + b);
    },
    refWebsite() {
      let refWebsite = this?.$route?.query?.ref;
      if (!refWebsite) return null;
      return refWebsite;
    },
  },

  methods: {
    async checkAchFeedbackStatus() {
      // 1. close add winners dialog
      // 2. get client IP address => https://www.ipify.org/
      // 3. check for already submitted ach payments feedback
      // 4. if doc exists return => else show achPaymentsFeedback dialog
      this.howToAddWinnerDialog = false;
      try {
        let ipRequest = await axios.get("https://api.ipify.org");
        let ipAddress = ipRequest.data;
        if (!ipAddress) return;
        let docRef = firebase
          .firestore()
          .collection("userFeedback")
          .doc("travelerPortal")
          .collection("achPayments")
          .doc(ipAddress);
        let doc = await docRef.get();
        if (doc.exists) return (this.achPaymentsDialog = false);
        this.ipAddress = ipAddress;
        this.achPaymentsDialog = true;
      } catch (error) {
        return (this.achPaymentsDialog = false);
      }
    },
    async onAchFeedback(value) {
      // 2. set doc w/IP address as doc ID
      if (value == undefined || value == null)
        return (this.achPaymentsDialog = false);
      this.$store.state.loading = true;
      await firebase
        .firestore()
        .collection("userFeedback")
        .doc("travelerPortal")
        .collection("achPayments")
        .doc(this.ipAddress)
        .set({
          dateSubmitted: new Date().toISOString().substring(0, 10),
          value: value,
          ipAddress: this.ipAddress,
        });
      this.$store.state.loading = false;
      this.achPaymentsDialog = false;
      await this.$store.dispatch("setSnackbar", {
        display: true,
        color: "success",
        text: "Thanks for the feedback!",
        timeout: 5000,
      });
    },
    handleScroll(event) {
      if (!event) return;
      if (this.winnerDialogAutoShown) return;
      const top = window.pageYOffset || 0;
      if (top >= 1250) return (this.howToAddWinnerDialog = true);
    },
    onSetWinnerInfo(
      index,
      { destination, costToOrg, roomType, numberNights, packageCode }
    ) {
      // setting display values
      this.winners[index].destination = destination;
      this.winners[index].costToOrg = costToOrg;
      this.winners[index].unitType = roomType.title;
      this.winners[index].numberNights = numberNights;
      this.winners[index].packageCode = packageCode;
      this.winners[index].travelByDate = this.eventDate
        ? moment(this.eventDate)
            .add(3, "y")
            .subtract(parseInt(this.winners[index].numberNights), "d")
            .toISOString()
            .substring(0, 10)
        : "";
      // sanitizing package code
      let sanitizedPackageCode = packageCode.toLowerCase();
      // removing the word sav
      if (/sav/i.test(sanitizedPackageCode)) {
        sanitizedPackageCode = sanitizedPackageCode.replace(/sav/g, "");
      }
      // removing hyphens
      if (/-/i.test(sanitizedPackageCode)) {
        sanitizedPackageCode = sanitizedPackageCode.replace("-", "");
      }
      // removing the word golf
      if (/golf/i.test(sanitizedPackageCode)) {
        sanitizedPackageCode = sanitizedPackageCode.replace("golf", "");
      }

      // setting winner packageID
      let newDate = new Date();
      let momentDateTime = moment(newDate).format("MM-DD-YYYY-hh-mm-ss-");
      this.winners[index].packageID = momentDateTime + sanitizedPackageCode;
    },
    async validateForm() {
      // validates form
      // if form not valid => error
      // if form valid w/1 winner => confirmSingleWinnerDialog
      // else submit regularly
      if (!this.$refs.form.validate()) {
        await this.$store.dispatch("setLoading", false);
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "error",
          text: "Please check form for errors",
          timeout: 10000,
        });
        return (this.confirmSingleWinnerDialog = false);
      } else if (this.winners.length == 1) {
        // form valid => confirm only single winner
        return (this.confirmSingleWinnerDialog = true);
      } else {
        // form valid => submit as usual
        return this.onSubmit();
      }
    },
    // async quickBookSync(id) {
    //   // this.loading = true;
    //   await axios
    //     .get(`https://api.silentauctionvacations.com/eventReport/${id}`)
    //     .then((res) => {
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    async onSubmit() {
      // double checking form validation
      if (!this.$refs.form.validate()) {
        await this.$store.dispatch("setLoading", false);
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "error",
          text: "Please check form for errors",
          timeout: 10000,
        });
        return (this.confirmSingleWinnerDialog = false);
      }

      // form valid
      this.confirmSingleWinnerDialog = false;
      await this.$store.dispatch("setLoading", true);

      // getting next event report number
      let eventReportsRef = firebase.firestore().collection("eventReports");
      let eventReports = await eventReportsRef.get();
      let lastEventReportNumber = eventReports.docs
        .map((i) => i.data().eventReportNumber)
        .sort((a, b) => a - b)
        .pop();
      let nextNumber = lastEventReportNumber + 1;
      this.eventReportNumber = nextNumber;

      // firestore doc id
      let newDate = new Date().toISOString();
      let dateValue = newDate
        .split("T")[0]
        .replace(/-/g, "")
        .trim();
      let timeValue = newDate
        .split("T")[1]
        .split(".")[0]
        .replace(/:/g, "")
        .trim();
      this.id =
        nextNumber +
        "-" +
        this.winners.length +
        "-" +
        dateValue +
        "-" +
        timeValue;

      // firestore eventReport data
      let data = {
        additionalEmail: this.additionalEmail,
        adminNotes: "",
        archived: this.archived,
        auctioneerCompany: this.auctioneerCompany,
        auctioneerEmail: this.auctioneerEmail,
        auctioneerName: this.auctioneerName,
        auctioneerUsed: this.auctioneerUsed,
        dateCreated: this.dateCreated,
        datePaid: this.datePaid,
        dateUpdated: this.dateUpdated,
        eventContactEmail: this.eventContactEmail,
        eventContactFirstName: this.eventContactFirstName,
        eventContactLastName: this.eventContactLastName,
        eventContactPhoneNumber: this.eventContactPhoneNumber,
        eventDate: this.eventDate,
        eventName: this.eventName,
        eventReportNumber: this.eventReportNumber,
        eventReportTotal: this.total,
        id: this.id,
        isPaid: this.isPaid,
        numberOfWinners: this.winners.length,
        organizationAddress: this.organizationAddress,
        organizationName: this.organizationName,
        refWebsite: this.refWebsite,
        reportCompletedBy: this.reportCompletedBy,
        reportCompletedByOtherName: this.reportCompletedByOtherName,
        savSalesName: this.savSalesName,
        savSalesOtherName: this.savSalesOtherName,
        savSalesUsed: this.savSalesUsed,
        sendToAdditionalEmail: this.sendToAdditionalEmail,
        sendToAuctioneerEmail: this.sendToAdditionalEmail,
        source: this.source,
        travelByDate: this.eventDate
          ? moment(this.eventDate)
              .add(3, "y")
              .toISOString()
              .substring(0, 10)
          : "",
        winnerPackageIDs: this.winners.map((i) => i.packageID),
        winners: this.winners,
      };

      // adding admin portal MPS entries
      for (let index = 0; index < data.winners.length; index++) {
        const el = data.winners[index];

        await firebase
          .firestore()
          .collection("mps")
          .add({
            traveler: el.lastName,
            puid: el.packageID,
            packageCode: el.packageCode,
            packageDestination: el.destination,
            dateCreated: new Date().toISOString().substring(0, 10),
            dateEdited: "",
            issueDate: moment(data.eventDate, "YYYY-MM-DD").format(
              "MM/DD/YYYY"
            ),
            sponsorOrganization: `${data.organizationName} / ${
              data.eventName
            } ${data.auctioneerName ? `/ ${data.auctioneerName}` : ""} ${
              data.auctioneerCompany ? `(${data.auctioneerCompany})` : ""
            } ${data.auctioneerEmail ? `/ ${data.auctioneerEmail}` : ""}`,
            sponsorOrgSeparate: {
              organizationName: data.organizationName,
              eventName: data.eventName,
              auctioneerName: data.auctioneerName,
              auctioneerCompany: data.auctioneerCompany,
              auctioneerEmail: data.auctioneerEmail,
            },
            sponsorOrgUpdate: `${data.organizationName} / ${
              data.eventName
            } ${data.auctioneerName ? `/ ${data.auctioneerName}` : ""} ${
              data.auctioneerCompany ? `(${data.auctioneerCompany})` : ""
            } ${data.auctioneerEmail ? `/ ${data.auctioneerEmail}` : ""}`,
          });
      }

      // setting firestore eventReport doc
      await firebase
        .firestore()
        .collection("eventReports")
        .doc(this.id)
        .set(data);

      // sync record to quickbook
      // await this.quickBookSync(this.eventReportNumber);

      // add admin notification
      await firebase
        .firestore()
        .collection("adminNotifications")
        .add({
          archived: false,
          dateAdded: new Date().toISOString().substring(0, 10),
          dateArchived: "",
          text: `[New Invoice Submitted - Traveler Portal] Organization: ${this.organizationName}, Event Name: ${this.eventName}, Invoice Total: $${this.total}`,
        });

      // send emails
      await axios.post("/api/new-event-report", { data: data });

      // success
      await this.$store.dispatch("setLoading", false);
      await this.$store.dispatch("setSnackbar", {
        display: true,
        color: "success",
        text: "Event Report Submitted Successfully",
        timeout: 5000,
      });
      this.successDialog = true;
      return;
    },
  },
  watch: {
    howToAddWinnerDialog(val) {
      if (!val) return;
      if (val) return (this.winnerDialogAutoShown = true);
    },
    winnerDialogAutoShown(val) {
      if (!val) return;
      window.removeEventListener("scroll", this.handleScroll);
    },
  },
};
</script>
